import React from 'react';
import Layout from 'components/Layout';
import "./../assets/stylesheets/pages/contact.scss";
import { Helmet } from "react-helmet"

const contactPage = () => {
  return (
    <Layout >
      <Helmet title="Dirk Mulder Portfolio | Contact">
        <meta charSet="utf-8" />
        <meta name="description" content="This the portfolio of Dirk Mulder. On this page you can contact me using a contact form.'" />
        <meta name="keywords" content="Dirk, Mulder, Contact" />

        <html lang="en" />
      </Helmet>

      <div className="contact" >
        <div className="contacttext">
          <h1>Contact Me</h1>
          <p>Please contact me using this form. I try to respond within a day.</p>
        </div>

        <form action="https://formcarry.com/s/4jldeqogb" method="POST" accept-charset="UTF-8">
          <div className="half">
            <label for="First name">First Name
            <input name="First name" type="text" placeholder="Jane" />
            </label>

          </div>
          <div className="half">
            <label for="Last name">Last Name
            <input name="Last name" type="text" placeholder="Doe" />
            </label>
          </div>

          <input type="hidden" name="_gotcha"></input>

          <label for="Email">Email
          <input name="Email" type="email" placeholder="info@example.com" />
          </label>

          <label for="Message">Message
          <textarea name="Message"></textarea>
          </label>

          <button type="submit">Send</button>
        </form>
      </div >
    </ Layout>
  );
  ;
}

export default contactPage

